<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        If hydrogen atoms are excited to the
        <stemble-latex content="$n=4$" />
        state, what is the maximum number of
        <span class="text-bold">emission lines</span> (lines of different wavelengths) that could be
        observed from this state?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Number:}$"
        append-text="$\text{lines}$"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        Determine the wavelength of the photon emitted when the electron in the hydrogen atom
        transitions from the
        <stemble-latex content="$n=4$" />
        to the
        <stemble-latex content="$n=$" />
        <number-value :value="energyLevel" />
        energy level in nanometers.
      </p>

      <calculation-input
        v-model="inputs.input2"
        prepend-text="$\lambda :$"
        append-text="$\text{nm}$"
        class="mb-2"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question16',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    energyLevel() {
      return this.taskState.getValueBySymbol('energyLevel').content;
    },
  },
};
</script>
